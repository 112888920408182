import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';

const flex = css`
  display: flex;
  align-items: center;
  padding: calc(var(--unit) * 2) calc(var(--unit) * 5);
  width: 100%;
  background: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
  &:first-of-type {
    margin-top: 0;
  }
`;

export const link = css`
  ${flex};
  color: hsl(var(--color-textLight-7));
  justify-content: space-between;

  .check,
  .eye {
    display: none;
  }

  &:hover {
    color: hsl(var(--color-grayscale-1));

    .eye {
      display: block;
    }
  }
`;

export const linkActive = css`
  ${flex};
  justify-content: space-between;
  color: hsl(var(--color-grayscale-1));
  font-weight: var(--font-weight-bold);
`;

export const root = css`
  margin-top: calc(var(--unit) * 3);
  font-size: var(--font-size-tiny);
  line-height: var(--line-height-tiny);
  padding: calc(var(--unit) * 2) 0;
  border-radius: 6px;
  width: calc(var(--unit) * 54);

  .eye {
    margin-left: auto;
    path {
      fill: hsl(var(--color-grayscale-6));
    }
  }
  .check {
    min-width: calc(var(--unit) * 4);
  }

  hr {
    margin: calc(var(--unit) * 2) 0;
    margin-left: calc(var(--unit) * 5);
    border-color: hsl(var(--color-border-7));
  }

  .link--active {
    ${linkActive};
    justify-content: left;
  }

  .link {
    ${link};
    justify-content: left;
  }
`;

export const avatarWrapper = css`
  display: flex;
  width: 80%;
  align-items: center;
`;

export const avatar = css`
  height: calc(var(--unit) * 5);
  width: calc(var(--unit) * 5);
  min-width: calc(var(--unit) * 5);
  margin-right: calc(var(--unit) * 2);

  & > div {
    & > span {
      ${font.size.micro}
    }
  }
`;

export const name = css`
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(var(--unit) * 29);
  white-space: nowrap;
`;

export const svgWrapper = css`
  width: calc(var(--unit) * 5);
  height: calc(var(--unit) * 5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(var(--unit) * 2);
`;

export const plus = css`
  & path {
    fill: hsl(var(--color-grayscale-6));
  }
`;

export const loading = css`
  &,
  svg {
    width: calc(var(--unit) * 4);
    height: calc(var(--unit) * 4);
  }
`;

export const loadingSvg = css`
  svg {
    width: ${spacing(16)};
    height: ${spacing(16)};
  }
`;

export const profilePicture = css`
  width: calc(var(--unit) * 8);
  height: calc(var(--unit) * 8);

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
      ${font.size.tiny}
    }
  }
`;

export const logoutDisabled = css`
  pointer-events: none;
  opacity: 0.6;
`;
